<template>
  <div class="menu-wrap">
    <div v-if="isMobile" class="open" @click="showMenu"><span></span></div>
    <div class="menu-block">
      <div v-if="isMobile" class="title">System Actions</div>

      <div class="menu-item" @click="openModalCabinet">
        <div class="icon" title="Личный кабинет">
          <img :src="icons.user" alt="" />
        </div>
        <span v-show="isMobile">Личный кабиинет</span>
      </div>

      <!--      <div class="menu-item" style="display: none" @click="changeTheme">light theme</div>-->

      <!--      <div class="menu-item" @click="updateChart">Обновить график</div>-->

      <!--      <div class="menu-item" @click="awaitUpdateWS">-->
      <!--        Обновить WS-->
      <!--        <div class="red-circle" style="display: none"></div>-->
      <!--      </div>-->

      <div class="menu-item" @click="logout">
        <div class="icon" title="Выход из аккаунта">
          <img :src="icons.iconLogout" alt="" />
        </div>
        <span v-show="isMobile"> Log out</span>
      </div>
    </div>
    <div class="overlay" @click="closeMenu"></div>

    <c-modal :cabinet="isMobile" :visible="modals.cabinet.visible" size="medium" title="Личный кабинет" @close="closeModal">
      <cabinet />
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';
import CModal from '@/components/UI/ComponentModal.vue';
import Cabinet from '@/components/cabinet/Index.vue';

export default {
  name: 'SystemMenu',
  emits: ['close'],
  components: { CModal, Cabinet },
  data() {
    return {
      icons,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      modals: 'getModals',
    }),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({
      restartServer: 'restartServer',
      updateWS: 'updateWS',
    }),
    changeTheme() {
      let root = document.querySelector(':root');
      if (root.classList.contains('dark')) {
        root.classList.remove('dark');
        root.classList.add('light');
      } else {
        root.classList.add('dark');
        root.classList.remove('light');
      }
    },
    updateChart() {
      this.closeMenu();
      this.restartServer();
    },
    async awaitUpdateWS(e) {
      const circle = e.target.querySelector('.red-circle');
      circle.style.display = 'block';
      await this.updateWS();
      circle.style.display = 'none';
    },
    closeMenu() {
      const bntMenu = document.querySelector('.menu-wrap .open');
      bntMenu.classList.remove('active');
    },
    showMenu(target) {
      target.srcElement.classList.toggle('active');
    },
    openModalSetting() {
      this.closeMenu();
      if (!this.isMobile) return void (this.$refs.modalSetting.show = true);
      this.$router.push({ name: 'setting' });
    },
    closeModal() {
      const currentUrl = window.location.href;
      const hashIndex = currentUrl.indexOf('#');

      if (hashIndex !== -1) {
        const newUrl = currentUrl.substring(0, hashIndex);
        window.history.replaceState(null, '', newUrl);
      }
      for (const key in this.modals) {
        this.modals[key].visible = false;
      }
    },
    openModalCabinet() {
      if (this.isMobile) this.closeMenu();
      this.changeModals({ cabinet: { visible: true, data: {} } });
    },
    closeAllWindow() {
      this.closeMenu();
    },
    logout() {
      this.$router.push({ name: 'login' });
    },
  },
  watch: {},
  created() {
    // setTimeout(() => {
    //   this.modals.cabinet.visible = true;
    // }, 500);
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';
/* MENU */
.open {
  width: 28px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: translate(0, -8px);

  span {
    height: 1px;
    display: inline-block;
    width: 100%;
    pointer-events: none;
    background-color: var(--icon-secondary-default-color);
    transition: all 0.3ms ease-in-out;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform-origin: center;
    transition: all 0.1ms ease-in-out;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: var(--icon-secondary-default-color);
  }

  &::after {
    bottom: 0;
    top: auto;
  }

  &.active {
    span {
      opacity: 0;
    }

    &::after {
      transform: rotate(45deg);
      top: 50%;
      bottom: auto;
    }

    &::before {
      transform: rotate(-45deg);
      top: 50%;
      bottom: auto;
    }

    & + .menu-block {
      transform: translateX(0);

      & + .overlay {
        pointer-events: all;
        visibility: visible;
      }
    }
  }
}

.menu-wrap {
  position: relative;
  display: flex;
  height: 100%;
  @include breakpoint-down(md) {
    height: auto;
  }
}

.menu-block {
  display: flex;
  @include breakpoint-down(md) {
    display: block;
    padding: 8px 0;
    width: 200px;
    position: absolute;
    right: 0;
    top: 150%;
    transform: translateX(130%);
    transition: all 0.1s ease;
    border: 1px solid;
    z-index: 4;
    border-color: var(--dropdown-list-border-color);
    background-color: var(--dropdown-list-bg-color);
    color: var(--dropdown-list-text-color);
    box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);
  }

  .title {
    position: relative;
    display: flex;
    padding: 4px 0 6px;
    font-size: 10px;
    line-height: 1;
    font-weight: 400;
    color: var(--dropdown-header-text-color);

    &::after {
      content: '';
      display: block;
      height: 1px;
      margin: 5px -1px 0 4px;
      flex-grow: 1;
      background: var(--line-color-2);
    }
  }

  .menu-item {
    color: var(--dropdown-list-text-color);
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    line-height: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 220ms ease-in;
    background-color: transparent;
    @include breakpoint-down(md) {
      box-shadow: inset 0 1px 0 0 var(--line-color-2);
      padding: 4px 8px;
    }

    &:hover {
      @include breakpoint-down(md) {
        background-color: var(--list-item-hovered-bg);
      }
    }

    @include breakpoint-down(md) {
      font-size: 14px;
      padding: 8px 8px;
    }

    .icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      @include breakpoint-down(md) {
        margin-right: 6px;
        margin-left: 0;
        width: 12px;
        height: 12px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  z-index: 3;
  pointer-events: none;
}

/* END MENU */

.red-circle {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
}
</style>
