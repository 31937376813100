<template>
  <div v-if="visible" :class="['modal-wrap', blackout ? 'blackout' : '']">
    <div class="modal-wrap-inside" @click.self="close">
      <div ref="modalBody" class="modal-body">
        <div class="modal-title" @mousedown="startDrag">
          {{ title }}
          <div class="modal-close" @click="close">&#10799;</div>
        </div>
        <div :class="[size, cabinet ? 'cabinet' : '', 'modal-content', noPadding ? 'no-padding' : '']">
          <div class="slot-content">
            <slot />
          </div>
          <div class="modal-footer">
            <div v-if="confirmation" class="btn-wrap">
              <button class="warning" @click="refuse">Отказаться</button>
              <button class="primary" @click="confirm">Подтвердить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import animation from '@/utils/animation';

export default {
  name: 'ComponentModal',
  emits: ['close', 'refuse', 'confirm'],
  components: {},
  data() {
    return {
      delay: 300,
    };
  },
  props: {
    title: {
      type: String,
    },
    blackout: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    cabinet: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      requared: true,
    },
    confirmation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    close(e) {
      const outside = e.target.classList.contains('modal-wrap-inside');
      animation.compression(e, outside ? 500 : 150);
      setTimeout(() => this.$emit('close'), this.delay);
    },
    refuse(e) {
      animation.pressing(e);
      setTimeout(() => this.$emit('refuse'), this.delay);
    },
    confirm(e) {
      animation.pressing(e);
      setTimeout(() => this.$emit('confirm'), this.delay);
    },
    startDrag(e) {
      this.startX = e.clientX;
      this.startY = e.clientY;
      const rect = this.$refs.modalBody.getBoundingClientRect();
      this.initialLeft = rect.left;
      this.initialTop = rect.top;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(e) {
      const dx = e.clientX - this.startX;
      const dy = e.clientY - this.startY;
      const newLeft = this.initialLeft + dx;
      const newTop = this.initialTop + dy;
      if (newTop >= 0) {
        this.$refs.modalBody.style.left = `${newLeft}px`;
        this.$refs.modalBody.style.top = `${newTop}px`;
      }
    },
    onMouseUp() {
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.slot-content {
  margin-bottom: auto;
  //padding-bottom: 50px;
}

.modal {
  &-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    padding: 0 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    &.blackout {
      background-color: var(--widget-bg-top-color);
    }
  }

  &-wrap-inside {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    background: linear-gradient(to bottom, var(--app-bg-grad-top-color), var(--app-bg-grad-bottom-color));
    box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);
    border-radius: var(--main-border-radius);
    overflow: hidden;
    position: absolute;
    @include breakpoint-down(md) {
      left: 5px;
      right: 5px;
      top: auto;
    }
  }

  &-content {
    padding: 20px;
    background: linear-gradient(to bottom, var(--widget-bg-top-color), var(--widget-bg-bottom-color));
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &.no-padding {
      padding: 0 !important;
    }

    &.large {
      min-width: 100%;
      min-height: 100%;
      width: 90vw;
      height: 90vh;
      @include breakpoint-down(md) {
        height: 80vh;
      }
    }

    &.medium {
      min-width: 100%;
      min-height: 100%;
      width: 60vw;
      height: 60vh;
    }

    &.small {
      min-width: 500px;
      min-height: 300px;
      @include breakpoint-down(md) {
        min-width: 100%;
      }
    }

    &.cabinet {
      width: 97vw;
      height: 85vh;
    }
  }

  &-title {
    color: var(--widget-header-text-color);
    background-image: linear-gradient(to right, var(--widget-header-grad-color-1), var(--widget-header-grad-color-2));
    box-shadow: 0 1px 0 0 var(--widget-header-shadow-color);
    font-size: 16px;
    font-weight: 500;
    padding: 3px 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    cursor: move;
  }

  &-footer {
    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 10px;

      button {
        margin: 0;
      }
    }
  }

  &-close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 30px;
  }
}
</style>
