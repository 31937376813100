<template>
  <label class="switch">
    <input :checked="modelValue" type="checkbox" @change="onInputChange" />
    <span class="slider">
      <span>I</span>
      <span>O</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'CustomCheck',
  emits: ['update:modelValue'],
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onInputChange(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
$radius: 4px;
$height: 20px;
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: $height;
  font-size: 14px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #494958;
  border-radius: $radius;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.62);
  border: solid 1px rgba(93, 93, 98, 0.62);
  font-weight: 600;

  span:first-child {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    user-select: none;
  }

  span:last-child {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    user-select: none;
  }
}

.slider {
  &:before {
    position: absolute;
    content: '';
    height: calc($height - 5px);
    width: calc($height - 5px);
    left: 2px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #c8d4db;
    border-radius: $radius;
    transition: transform 0.3s ease;
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

input:checked + .slider {
  background-color: #242637;
}

input:checked + .slider:before {
  transform: translate(18px, -50%);
}
</style>
