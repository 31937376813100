<template>
  <div class="cabinet__item-block">
    <div>
      <c-label text="Номер счета:" />
      <div class="item__value">{{ accountSelected }}</div>
    </div>
    <div>
      <c-label text="Клиент:" />
      <div class="item__value">{{ data?.full_name }}</div>
    </div>
    <div>
      <c-label text="Брокер:" />
      <div class="item__value">{{ data?.broker_name }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel },
  data: function () {
    return {
      data: null,
    };
  },
  computed: {
    ...mapGetters({
      getAccounts: 'getAccounts',
      accountSelected: 'getAccountSelected',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    getData() {
      this.data = this.getAccounts[this.accountSelected];
      console.log('data :', this.data);
    },
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
