<template>
  <component-label
    hint-text="Автоследование допускает автоматическое копирование сделки Управляющего по выбранной стратегии, без непосредственного участия Клиента при совершении каждой сделки."
    text="Что это"
  />
  <button class="send primary" @click.prevent="openAutoFollow">Подписать автоследование</button>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ComponentLabel from '@/components/UI/ComponentLabel.vue';
import animation from '@/utils/animation';

export default {
  name: 'AutoFollow',
  components: { ComponentLabel },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({}),
    openAutoFollow(e) {
      this.changeModals({ autoFollow: { visible: true, data: {} } });
      animation.pressing(e);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
