<template>
  <div :class="scale ? 'scale' : ''" class="custom-select">
    <div :class="isDropdownOpen ? 'wide' : ''" class="custom-select-wrap">
      <input v-model="hiddenValue" :required="required" style="position: absolute; inset: 0; z-index: -1" type="text" />
      <div :data-value="localValue" class="select-header" @click="toggleDropdown">
        <span :class="[disabled ? 'disabled' : '', optionContent('type').includes('draft') ? 'gray' : '', 'title']">{{
          optionContent('text').join(', ')
        }}</span>
        <span :class="{ 'arrow-up': isDropdownOpen }" class="arrow"></span>
      </div>
      <div v-if="isDropdownOpen" :class="['options', direction]">
        <input v-if="searchable" ref="searchInput" v-model="searchQuery" placeholder="Search..." type="text" @input="filterOptions" />
        <div v-if="user.type === 'manager' && Object.keys(filters).length > 0" class="row">
          <div v-for="filter in filters" :key="filter.field">
            <c-check
              v-if="filter.type === 'checkbox'"
              v-model="filter.checked"
              :name="filter.field"
              :title="filter.text"
              :type="filter.type"
            />
          </div>
        </div>
        <div class="options-items">
          <div
            v-for="option in processingOptions(options)"
            :key="option.value"
            :class="[option.type === 'draft' ? 'gray' : '', localValue.includes(option.value) ? 'selected' : '', 'item']"
            :title="option.text"
            @click="selectOption(option)"
          >
            <span>{{ option.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CCheck from '@/components/UI/ComponentCustomCheck.vue';

export default {
  name: 'ComponentSelect',
  components: { CCheck },
  props: {
    disabled: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    scale: { type: Boolean, default: false },
    options: Array,
    modelValue: [String, Number, Array],
    defaultOption: [String, Number],
    onChange: Function,
    filters: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
    multiple: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isDropdownOpen: false,
      searchQuery: '',
      hiddenValue: null,
      direction: 'down',
    };
  },
  computed: {
    localValue: {
      get() {
        if (this.modelValue === undefined || this.modelValue === null) return [];
        return Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue];
      },
      set(value) {
        if (!this.multiple) value = value[0] || '';
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) {
        this.isDropdownOpen = !this.isDropdownOpen;
        if (this.isDropdownOpen) {
          this.checkDropdownDirection();
        }
      }
    },
    checkDropdownDirection() {
      this.$nextTick(() => {
        const select = this.$el;
        const options = select.querySelector('.options');
        const selectRect = select.getBoundingClientRect();
        const container = select.closest('form') || select.closest('.main-template');
        if (options && container) {
          const containerRect = container.getBoundingClientRect();
          if (selectRect.bottom + options.offsetHeight < containerRect.bottom) {
            this.direction = 'down';
          } else if (selectRect.top - options.offsetHeight > containerRect.top) {
            this.direction = 'up';
          } else {
            this.direction = selectRect.top - containerRect.top > containerRect.bottom - selectRect.bottom ? 'up' : 'down';

            let maxHeight = options.offsetHeight;
            if (this.direction === 'down') {
              const bottomSize = containerRect.bottom - selectRect.bottom;
              if (bottomSize < maxHeight) maxHeight = bottomSize - 10;
            } else {
              const topSize = selectRect.top - containerRect.top;
              if (topSize < maxHeight) maxHeight = topSize - 10;
            }

            options.style.maxHeight = `${maxHeight}px`;
          }
        }
      });
    },
    optionContent(param = 'text') {
      return this.localValue.map((val) => this.options.find((option) => option.value === val)?.[param] || null);
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$el.contains(event.target)) this.isDropdownOpen = false;
    },
    processingOptions(options) {
      for (const key in this.filters) {
        if (this.filters[key].checked === true) {
          options = options.filter((each) => each.contactId === this.user.id || this.filters[key].value.includes(each[key]));
        }
      }
      const query = this.searchQuery.toLowerCase();
      return options.filter((option) => option.text.toLowerCase().includes(query));
    },
    selectOption(option) {
      if (this.multiple) {
        const arrValue = [...this.localValue];
        const index = arrValue.indexOf(option.value);
        index > -1 ? arrValue.splice(index, 1) : arrValue.push(option.value);
        this.localValue = [...this.sortByOptions(arrValue)];
      } else {
        this.isDropdownOpen = false;
        this.localValue = [option.value];
      }
      if (this.onChange) this.onChange(option.value);
    },
    sortByOptions(arr) {
      return arr.sort((a, b) => {
        const indexA = this.options.findIndex((item) => item.value === a);
        const indexB = this.options.findIndex((item) => item.value === b);
        return indexA - indexB;
      });
    },
  },
  watch: {
    localValue(newValue) {
      if (newValue.length > 0) this.hiddenValue = this.localValue;
    },
    isDropdownOpen(newState) {
      if (newState === false) {
        this.searchQuery = '';
      }
    },
  },
  mounted() {
    if (this.localValue && this.localValue.length > 0) this.hiddenValue = this.localValue;
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
};
</script>

<style lang="scss" scoped>
@import '../../style/helper/index';

.custom-select {
  position: relative;
  color: var(--black);
  width: 100%;
  height: 24px;

  &-wrap {
    height: 100%;
  }
}

.scale {
  z-index: 333333;
  height: 100%;

  .custom-select-wrap {
    z-index: 10;
    height: 100%;

    &:hover,
    &.wide {
      position: absolute;
      min-width: 100%;
      @include breakpoint-down(md) {
        max-width: 230px;
      }

      .select-header {
        padding: 5px 10px;
      }
    }
  }
}

.select-header {
  cursor: pointer;
  border-radius: var(--main-border-radius);
  padding: 0 10px;
  transition: all 0.1s ease;
  -webkit-appearance: none;
  background-color: var(--input-alt-default-bg);
  border: 1px solid var(--input-alt-default-border-outside);
  color: var(--input-alt-default-text);
  box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
  display: flex;
  align-items: center;
  height: 100%;

  @include breakpoint-down(md) {
    height: 24px;
  }

  .title {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
}

.arrow-up {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 5px 6px 10px;
  border: 1px solid;
  border-color: var(--dropdown-list-border-color);
  background-color: var(--dropdown-list-bg-color);
  color: var(--dropdown-list-text-color);
  box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);
  font-size: 12px;
  border-radius: var(--main-border-radius);
  min-width: 70px;
  transition: top 0.3s ease;
  overflow-y: auto;

  &.down {
    top: 100%;
  }

  &.up {
    bottom: calc(100% + 5px);
  }

  input {
    width: 100%;
    margin-bottom: 2px;
    height: 30px;
    padding: 2px 10px;
    font-size: 14px;
  }

  .options-items {
    max-height: calc(90vh - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0 5px;
    margin-top: 5px;
    width: 100%;
  }

  .row {
    display: flex;
  }
}

.options .item {
  padding: 5px 10px;
  cursor: pointer;
  transition:
    background 0.2s ease,
    transform 0.1s ease-in-out;
  font-size: 14px;
  border-radius: var(--main-border-radius);
  transform: translate(0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 1px;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 48px;
  }
}

.selected {
  background-color: var(--list-item-hovered-bg);
}

.options .item:hover {
  background-color: var(--list-item-hovered-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.disabled {
  color: #6c757d;
}

.gray {
  color: rgba(128, 128, 128, 1);
}
</style>
