<template>
  <div class="component-label">
    <div v-if="isHtmlContent(text)" class="v-html" v-html="text"></div>
    <div v-else class="text">{{ text }}</div>

    <div v-if="hintText" ref="hintWrap" class="hint__wrap">
      <div class="hint__icon" @click="toggleHint">?</div>
      <transition name="fade">
        <div v-if="isHintVisible" :style="hintPositionStyle" class="hint__text">
          <div class="wrap">
            <span>{{ hintText }}</span>
            <div><a v-if="hintLink" :href="hintLink" rel="noopener noreferrer" target="_blank">Подробнее</a></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentLabel',
  props: {
    text: { type: String, default: () => '' },
    hintText: { type: String, default: () => '' },
    hintLink: { type: String, default: null },
  },
  data() {
    return {
      isHintVisible: false,
      hintPositionStyle: {},
    };
  },
  methods: {
    isHtmlContent(text) {
      const htmlPattern = /<\/?[a-z][\s\S]*>/i;
      return htmlPattern.test(text);
    },
    toggleHint() {
      this.isHintVisible = !this.isHintVisible;
    },
    hideHint() {
      this.isHintVisible = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.hintWrap || !this.$refs.hintWrap.contains(event.target)) {
        this.hideHint();
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.component-label {
  color: var(--table-column-text-header-color);
  font-size: 12px;
  display: inline-flex;
  position: relative;
  width: 100%;

  .text {
    margin-bottom: 4px;
    font-weight: 400;
  }

  .hint {
    &__wrap {
      margin-left: 5px;
    }

    &__icon {
      background: rgba(0, 0, 0, 0.17);
      color: rgba(225, 225, 225, 1);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      cursor: pointer;
      transform: translate(0px, -1px);
    }

    &__text {
      transition: all 0.3s ease-in-out;
      position: absolute;
      bottom: 105%;
      left: 0;
      width: 100%;
      font-size: 11px;
      color: #fff;
      font-weight: 500;
      z-index: 2;

      .wrap {
        background: rgba(0, 0, 0, 0.9);
        padding: 6px 8px;
        display: inline-block;
        border-radius: var(--main-border-radius);
      }

      a {
        color: rgba(144, 145, 177, 1);
        text-decoration: underline;
        font-size: 10px;

        &:hover {
          color: #357ab7;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 5s ease,
    transform 5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
