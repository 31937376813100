<template>
  <div class="auto-approval">
    <div class="auto-approval-title">Ознакомьтесь перед подключением автоследования</div>

    <div class="items">
      <div class="item">
        <div class="item-title">Уведомление о рисках</div>
        <div class="item-text">
          <p>
            Подключая Услугу Клиент принимает и полностью осознает, что Стратегия Управляющего не гарантирует прибыли в будущем и подвержена
            рискам снижения стоимости портфеля.
          </p>
          <p>
            Перед подключением Услуги Клиент в обязательном порядке должен провести собственный анализ портфеля и выбирать Стратегию
            Управляющего с осторожностью.
          </p>
        </div>
      </div>

      <div class="item">
        <div class="item-title">Права и обязанности клиента</div>
        <div class="item-text">
          <p>
            Клиент обязуется использовать Услугу только для личных инвестиционных целей, не передавать доступ третьим лицам к своим учетным
            данным, самостоятельно контролировать инвестиции и принимать решения о выборе Стратегии.
          </p>
          <p>
            Клиент несет ответственность за сохранность своих учетных данных и обязуется немедленно уведомить Компанию о любом
            несанкционированном доступе к своему счету.
          </p>
          <p>Клиент имеет право в любой момент отключить Услугу, путем письменного уведомления Управляющего.</p>
        </div>
      </div>

      <div class="item">
        <div class="item-title">Права и обязанности компании</div>
        <div class="item-text">
          <p>Управляющий обязуется предоставлять Клиенту Услугу в соответствии с условиями настоящего Соглашения.</p>
          <p>
            Управляющий оставляет за собой право в любой момент изменить условия предоставления Услуги, уведомив об этом Клиента за 3
            рабочих дня до изменений.
          </p>
          <p>
            Управляющий не несет ответственности за результаты торговли, осуществляемой Клиентом с помощью Услуги, и не гарантирует прибыль.
          </p>
        </div>
      </div>
    </div>

    <div>
      <form class="form" @submit.prevent="sendApproval">
        <div class="auto-approval-form">
          <div>Я ознакомился с информацией и хочу подписать автоследование</div>
          <c-switch v-model="localAutoApproval" />
        </div>
        <button :disabled="!localAutoApproval" class="send primary center" type="submit">Отправить</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CSwitch from '@/components/UI/ComponentSwitch.vue';

export default {
  name: 'AutoApproval',
  components: { CSwitch },
  data: function () {
    return {
      localAutoApproval: false,
    };
  },
  computed: {
    ...mapGetters({
      autoApproval: 'getAutoApproval',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      updateAutoApproval: 'updateAutoApproval',
    }),
    sendApproval() {
      this.updateAutoApproval({ type: 'autoApproval', data: { autoApproval: this.localAutoApproval } });
    },
  },
  watch: {},
  created() {
    this.localAutoApproval = this.autoApproval;
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '../../style/helper/index';

.auto-approval {
  max-width: 600px;
  @include breakpoint-down(md) {
    height: 600px;
    overflow-y: auto;
  }

  &-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
  }

  .items {
    .item {
      margin-bottom: 15px;
      font-size: 14px;
    }

    .item-title {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .item-text {
      font-size: 13px;
      text-indent: 5px;

      p {
        margin-bottom: 5px;
      }
    }
  }

  .form {
    @include breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .auto-approval-form {
    display: flex;
    gap: 0 20px;
    margin-bottom: 30px;

    input {
      width: auto;
      height: auto;
    }
  }
}
</style>
