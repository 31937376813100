<template>
  <div class="order-setting">
    <!--    <div class="order-setting__header">-->
    <!--      В этом разделе вы можете настроить количество инструментов при выставлении приказа. По умолчанию количество равняется 100 бумагам.-->
    <!--    </div>-->

    <div class="order-setting__item">
      <div>
        <component-label hint-text="Укажите из чего будет выставляться количество бумаг" text="Выберите тип рассчета" />
        <c-select v-model="selectedType" :options="type" />
      </div>

      <div class="order-setting__input">
        <component-label :hint-text="hintText" :text="placeholderText" />
        <input v-if="selectedType" :placeholder="placeholderText" :value="inputValue" type="tel" @input="updateInputValue" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ComponentLabel from '@/components/UI/ComponentLabel.vue';
import CSelect from '@/components/UI/ComponentSelect.vue';

export default {
  name: 'OrderSetting',
  components: { CSelect, ComponentLabel },
  data: function () {
    return {
      selectedType: null,
      inputValue: null,
      type: [
        { value: 'sum', text: 'Сумма $' },
        { value: 'percent', text: 'Процент %' },
        { value: 'item', text: 'Количество шт' },
      ],
      defaultValues: {
        sum: 10000,
        percent: 10,
        item: 100,
      },
    };
  },
  computed: {
    ...mapGetters({
      orderSetting: 'getOrderSetting',
    }),
    texts() {
      return {
        item: {
          placeholder: 'Введите количество шт',
          hint: 'Количество бумаг будет отображаться при выставлении приказа',
        },
        sum: {
          placeholder: 'Введите сумму $',
          hint: 'Количество бумаг будет рассчитываться из указанной суммы',
        },
        percent: {
          placeholder: 'Введите процент %',
          hint: 'Количество бумаг будет рассчитываться из указанного процента от суммы денег',
        },
      };
    },
    placeholderText() {
      return this.texts[this.selectedType]?.placeholder || '';
    },
    hintText() {
      return this.texts[this.selectedType]?.hint || '';
    },
    // placeholderText() {
    //   if (this.selectedType === 'item') return 'Введите количество шт';
    //   else if (this.selectedType === 'sum') return 'Введите сумму $';
    //   else if (this.selectedType === 'percent') return 'Введите процент %';
    //   else return '';
    // },
    // hintText() {
    //   if (this.selectedType === 'item') return 'Количество бумаг будет отображаться при высттавлении приказа';
    //   else if (this.selectedType === 'sum') return 'Количество бумаг будет рассчитываться из указанной суммы';
    //   else if (this.selectedType === 'percent') return 'Количество бумаг будет рассчитываться из указанного процента от суммы денег';
    //   else return '';
    // },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      updateSettings: 'updateSettings',
    }),
    updateInputValue(e) {
      const value = e.target.value;
      const pattern = /^[1-9]\d{0,9}$/;
      if (pattern.test(value) || value === '') {
        if (this.selectedType === 'percent' && parseInt(value) > 100) e.target.value = this.inputValue;
        else {
          this.inputValue = value;
          if (value !== '') {
            this.updateSettings({
              setting: { main: { orderType: this.selectedType, orderValue: this.inputValue } },
              update: true,
            });
          }
        }
      } else e.target.value = this.inputValue;
    },
  },
  watch: {
    orderSetting: {
      handler(newState) {
        if (newState?.type) {
          this.selectedType = newState.type;
          this.inputValue = newState.value;
        }
      },
      immediate: true,
      deep: true,
    },
    selectedType(newType) {
      this.inputValue = this.defaultValues[newType] || null;
      this.updateSettings({
        setting: { main: { orderType: this.selectedType, orderValue: this.inputValue } },
        update: true,
      });
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.order-setting {
  //&__header {
  //  margin-bottom: 20px;
  //}

  &__item {
    display: flex;
    gap: 0 30px;

    @include breakpoint-down(md) {
      flex-direction: column;
      gap: 15px 0;
    }
  }

  &__input {
    @include breakpoint-down(md) {
      input {
        width: 100%;
      }
    }
  }
}
</style>
