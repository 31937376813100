<template>
  <div class="withdrawal">
    <form class="form" @submit.prevent="sendWithdrawal">
      <div class="form__unit_inline">
        <div>
          <component-label text="Укажите сумму" />
          <input v-model="summ" type="tel" @input="updateValue" />
        </div>
        <div>
          <component-label hint-text="По умолчанию вы используете заёмные средства" text="Собственные средства" />
          <custom-check v-model="ownMoney" />
        </div>
      </div>
      <div class="form__unit">
        <button class="send primary" type="submit">Запросить</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ComponentLabel from '@/components/UI/ComponentLabel.vue';
import CustomCheck from '@/components/UI/ComponentCustomCheck.vue';

export default {
  name: 'WithdrawalComponent',
  components: { CustomCheck, ComponentLabel },
  data: function () {
    return {
      ownMoney: false,
      summ: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      withdrawal: 'updateWithdrawal',
    }),
    sendWithdrawal() {
      this.withdrawal({ type: 'withdrawal', data: { money: this.ownMoney, summ: this.summ } });
    },
    updateValue(e) {
      const pattern = /^[1-9]\d{0,9}([.,]\d{0,2})?$/;
      let inputValue = e.target.value.replace(',', '.');

      if (!pattern.test(inputValue)) {
        inputValue = inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (inputValue.includes('.')) {
          const [integerPart, decimalPart] = inputValue.split('.');
          inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        }
      }
      e.target.value = inputValue;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
