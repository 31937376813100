<template>
  <div>
    <c-title title="Watch list">
      <template #instrument>
        <div class="add-watch" @click.stop="addInstrument"><img :src="icons.addWatch" alt="" /></div>
      </template>
    </c-title>
    <div v-if="user.type === 'manager'" class="wl-names">
      <div v-for="name of user.wl" :key="name" :class="['wl-item', selectedWl === name ? 'active' : '']" @click="change(name)">
        {{ name.toUpperCase() }}
        <!-- <div v-if="!['main', 'hot'].some((item) => name.includes(item))" class="close-wl-button">&#10799;</div> -->
      </div>

      <div class="add-wl-button" @click="addNewWl"><img :src="icons.plus" alt="" /></div>
    </div>
    <div class="scrollable-wrap">
      <c-table
        :columnDefs="dynamicColumnDefs"
        :rows="localWL.filter((each) => each.data.name === selectedWl)"
        @delete="deleteInstrumentWL"
        @favorite="setFavorite"
        @select="selectRow"
      ></c-table>
    </div>
    <c-modal ref="modalProfile" :visible="modals.addInstrument.visible" title="Добавить инструмент" @close="closeModal">
      <search-list />
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import CTable from '@/components/UI/ComponentTable';
import CTitle from '@/components/UI/ComponentBlockTitle.vue';
import CModal from '@/components/UI/ComponentModal.vue';
import SearchList from '@/components/SearchList';

export default {
  name: 'watchlist-component',
  components: { CTable, CModal, SearchList, CTitle },
  data() {
    return {
      icons,
      localWL: [],
      columnDefs: [
        { name: 'Тикер', field: 'symbol', type: 'string', sort: true, search: 'text', flex: 1 },
        { name: 'Посл.цен', field: 'price', type: 'currency', sort: true, flex: 1 },
        { name: 'Изм.', field: 'change', type: 'currency', sort: true, flex: 1 },
        { name: 'Изм.в %', field: 'changeP', type: 'percent', sort: true, flex: 1 },
        { name: '', field: 'delete', hidden: true },
        // { name: '', field: 'favoriteRow', hidden: true },
      ],
      modals: {
        addInstrument: { visible: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      symbols: 'getSymbols',
      watchList: 'getWatchList',
      isMobile: 'isMobile',
      selectedWl: 'getSelectedWl',
      user: 'getUser',
    }),
    dynamicColumnDefs() {
      let columns = [...this.columnDefs];
      if (this.selectedWl === 'main' && this.user.type === 'manager')
        columns.push({
          name: '',
          field: 'favoriteRow',
          hidden: true,
        });
      return columns;
    },
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
      changeSelectedWl: 'changeSelectedWl',
    }),
    ...mapActions({
      deleteInstrumentWL: 'deleteInstrumentWL',
      updateSelectedSymbol: 'updateSelectedSymbol',
      addInstrumentWL: 'addInstrumentWL',
    }),
    addNewWl() {
      this.user.wl.push('WL' + this.user.wl.length);
    },
    change(name) {
      this.changeSelectedWl(name);
    },
    openSearchList() {
      this.modals.addInstrument.visible = true;
    },
    closeModal() {
      this.modals.addInstrument.visible = false;
    },
    addInstrument() {
      this.isMobile ? this.$router.push({ name: 'search.list' }) : this.openSearchList();
    },
    existInHot(symbol, list) {
      return list.findIndex((item) => item.symbol === symbol && item.name === 'hot') === -1 ? false : true;
    },
    setFavorite(item) {
      this.existInHot(
        item.symbol,
        this.localWL.map((each) => each.data),
      )
        ? this.deleteInstrumentWL({ name: 'hot', instrument: item.instrument })
        : this.addInstrumentWL({ name: 'hot', instrument: item.instrument });
    },
    selectRow(data) {
      this.updateSelectedSymbol(data.symbol);
      this.setDefaultOrder();
    },
  },
  watch: {
    watchList: {
      handler(newState) {
        this.localWL = [];
        for (const each of newState) {
          let favoriteRow = undefined;
          if (each.name !== 'hot') {
            favoriteRow = this.existInHot(each.symbol, newState);
            // console.log('symol :', each.symbol);
            // console.log('state :', newState);
          }
          this.localWL.push({
            symbol: each.symbol,
            price: each.price,
            key: each.symbol,
            change: each.change,
            changeP: each.changeP,
            data: each,
            deletedRow: true,
            favoriteRow,
          });
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.scrollable-wrap {
  height: 100%;
}

.wl-names {
  display: flex;
  align-items: center;
  padding: 0 5px;

  .wl-item {
    color: rgba(163, 166, 204, 1);
    border: solid 1px rgba(66, 69, 108, 1);
    transition: color 0.2s ease-in-out;
    padding: 0 5px;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgb(66, 69, 108);
    }

    &:not(:last-child) {
      margin-right: 1px;
    }
  }
}

.add-wl-button {
  border: solid 1px rgba(66, 69, 108, 1);
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.close-wl-button {
  width: 10px;
  height: 10px;
  font-size: 22px;
  line-height: 100%;
  transform: translate(3px, -9px);
}

.add-watch {
  width: 14px;
  height: 14px;
  margin-left: 6px;
  cursor: pointer;

  img {
    width: 100%;
  }

  @include breakpoint-down(md) {
    width: 18px;
    margin-left: 12px;
  }
}
</style>
