<template>
  <div class="order-form" @keydown="keyProcessing" @wheel="scrollProcessing">
    <form class="form">
      <div class="ticker-wrap">
        <div>{{ order.deal.orders[1].action === 'buy' ? 'Купить' : 'Продать' }}</div>
        <div>{{ order.deal.general.symbol }}</div>

        <div :class="['icon-float', floatingForm ? 'non-floating' : '']" @click="changeMode">
          <img :src="icons.output" alt="" />
        </div>
      </div>

      <div class="form__unit_inline">
        <div data-field="type">
          <c-label :hint-link="currentTypeData.hintLink" :hint-text="currentTypeData.hintText" text="Тип" />
          <c-select v-model="order.deal.orders[1].type" :options="orderTypes" />
        </div>
        <div data-field="tif">
          <c-label :hint-link="currentClosingType.hintLink" :hint-text="currentClosingType.hintText" text="Тип закрытия" />
          <c-select v-model="order.deal.orders[1].tif" :options="timePeriods" />
        </div>
      </div>

      <div class="form__unit_inline">
        <div class="item" data-field="quantity">
          <c-label text="Количество" />
          <input
            :value="(order.deal.general.quantity || 0.0).toString().replace('.', ',')"
            inputmode="decimal"
            type="text"
            @input="updateQuantity"
          />
        </div>
        <div class="cost">
          <c-label text="Стоимость" />
          <div class="cost-value" v-text="'~ ' + calcCost(bid || ask)"></div>
        </div>
      </div>

      <div class="form__unit_inline">
        <div class="item" data-field="triggerPrice">
          <c-label text="Стоп цена" />
          <input
            :disabled="!isActive('triggerPrice')"
            :value="priceValue('triggerPrice')"
            inputmode="decimal"
            type="text"
            @input="updatePrice"
          />
        </div>
        <div class="item" data-field="limitPrice">
          <!-- <div :class="[{ danger: danger && !order.deal.orders[1].limitPrice && this.order.deal.orders[1].type === 'limit' }, 'col-item']"> -->
          <c-label text="Цена" />
          <input
            :disabled="!isActive('limitPrice')"
            :value="priceValue('limitPrice')"
            inputmode="decimal"
            type="text"
            @input="updatePrice"
          />
        </div>
      </div>

      <div class="form__unit_inline">
        <div>
          <div class="bid" title="BID">
            <div class="triangle up"></div>
            <div class="title">BID</div>
            <div>
              <span>{{ printLevelI('bid', 'currency') }}</span>
              <br />
              <sup>{{ printLevelI('bidSize', 'size') }}</sup>
            </div>
          </div>
        </div>
        <div>
          <div class="ask" title="ASK">
            <div class="triangle down"></div>
            <div class="title">ASK</div>
            <div>
              <span>{{ printLevelI('ask', 'currency') }}</span>
              <br />
              <sup>{{ printLevelI('askSize', 'size') }}</sup>
            </div>
          </div>
        </div>
      </div>

      <!--      <textarea v-model="order.deal.general.description" />-->

      <div class="btn-wrap">
        <div
          v-for="action in actions"
          :key="action.value"
          :class="[action.value, { active: order.deal.orders[1].action === action.value }]"
          class="btn"
          @click.stop="order.deal.orders[1].action = action.value"
        >
          {{ action.text }}
        </div>
      </div>

      <div class="order-form-bottom">
        <button v-if="['demo'].includes(user.type)" class="primary center" @click="openAccount">Открыть счет</button>
        <button v-else :disabled="order.list[0].contract_status !== 'Signed'" class="primary center" @click="send">Send</button>
      </div>
    </form>
  </div>

  <c-modal ref="modalForm" size="medium" title="Форма ?">
    <mass-order-component />
  </c-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';
import animation from '@/utils/animation';
import utils from '@/utils';
import CSelect from '@/components/UI/ComponentSelect.vue';
import CModal from '@/components/UI/ComponentModal.vue';
import CLabel from '@/components/UI/ComponentLabel.vue';
import MassOrderComponent from '@/components/MassOrderForm.vue';

export default {
  name: 'order-form-component',
  components: {
    CLabel,
    CModal,
    CSelect,
    MassOrderComponent,
  },
  data() {
    return {
      icons,
      orderTypeData: {
        market: {
          hintText:
            'Обычная заявка: очень часто используется у "новичков" на рынках.\n' +
            'Условие выставления читается: купить по любой цене или продать не дешевле цены 0.',
          hintLink: 'https://ptfin.kz/dictionary#market',
        },
        limit: {
          hintText: 'Обычная заявка: частный случай Рыночной заявки. Основная заявка для работы на рынках.',
          hintLink: 'https://ptfin.kz/dictionary#limit',
        },
        stop: {
          hintText:
            'Условная заявка: часто используется для ограничения убытков или для входа в сделку при пробитии ценой какого либо уровня цен.',
          hintLink: 'https://ptfin.kz/dictionary#stop_market',
        },
      },
      orderClosingType: {
        gtc: {
          hintText: 'Выставить приказ со сроком действия до отмены.',
          hintLink: 'https://ptfin.kz/dictionary#gtc',
        },
        day: {
          hintText: 'Выставить приказ со сроком действия до конца текущей торговой сессии.',
          hintLink: 'https://ptfin.kz/dictionary#day',
        },
      },
      bid: 0.0,
      bidSize: 0.0,
      ask: 0.0,
      askSize: 0.0,
      timeoutId: 0,
      touch: { startX: 0.0, startY: 0.0, value: 0.0 },
      actions: [
        { value: 'buy', text: 'Buy' },
        { value: 'sell', text: 'Sell' },
      ],
      orderTypes: [
        { value: 'market', text: 'Market', available: [] },
        { value: 'limit', text: 'Limit', available: ['limitPrice'] },
        { value: 'stop', text: 'Stop', available: ['limitPrice', 'triggerPrice'] },
        // { value: 'stop_limit', text: 'Stop', available: ['limitPrice', 'triggerPrice'] },
      ],
      timePeriods: [
        { value: 'gtc', text: 'GTC - Good till Canceled' },
        { value: 'day', text: 'DAY' },
      ],
      confirm: {
        order: { modal: false },
        short: { modal: false, isSort: false },
      },
    };
  },
  computed: {
    utils() {
      return utils;
    },
    ...mapGetters({
      isMobile: 'isMobile',
      floatingForm: 'floatingForm',
      user: 'getUser',
      symbols: 'getSymbols',
      levelI: 'getLevelI',
      order: 'getOrder',
      orders: 'getOrders',
      portfolio: 'getPortfolio',
    }),
    currentTypeData() {
      return (
        this.orderTypeData[this.order.deal.orders[1].type] || {
          hintText: 'Unknown order type.',
          hintLink: '#',
        }
      );
    },
    currentClosingType() {
      return (
        this.orderClosingType[this.order.deal.orders[1].tif] || {
          hintText: 'Unknown order type.',
          hintLink: '#',
        }
      );
    },
  },
  methods: {
    ...mapMutations({
      toggleFloatingForm: 'toggleFloatingForm',
      changeOrder: 'changeOrder',
      changeNotifications: 'changeNotifications',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      sendOrder: 'sendOrder',
      pullQuoteSymbol: 'pullQuoteSymbol',
    }),
    changeMode() {
      this.toggleFloatingForm();
      if (!this.floatingForm) return void this.changeModals({ orderForm: { visible: false, data: {} } });
      this.changeModals({ orderForm: { visible: true, data: {} } });
    },
    changeType(type) {
      this.changeOrder({ type });
    },
    isActive(field) {
      return this.orderTypes.find((item) => item.value === this.order.deal.orders[1].type).available.includes(field);
    },
    priceValue(field) {
      return this.order.deal.orders[1][field] === 0 ? '' : this.order.deal.orders[1][field].toString().replace('.', ',');
    },
    calcCost() {
      const symbol = this.symbols[this.order.deal.general.symbol];
      let multiplier = 1.0;
      if (symbol && symbol.asset_category === 'OPT') multiplier = 100.0;
      let price = this.order.deal.orders[1].limitPrice;
      if (price === 0.0) price = (this.order.deal.orders[1].action === 'buy' ? this.ask : this.bid) * multiplier;
      return this.order.deal.general.quantity > 0 && price
        ? utils.getLocateCurrency(this.order.deal.general.quantity * price * multiplier)
        : null;
    },
    send(e) {
      e.preventDefault();
      animation.pressing(e);
      this.sendOrder();
    },
    openAccount(e) {
      e.preventDefault();
      this.changeModals({ boarding: { visible: true, data: {} } });
    },
    onlyFloat(e) {
      // if (!/[0-9,]/.test(String.fromCharCode(e.keyCode))) e.preventDefault();
      e.target.value = e.target.value
        .replace(/[^0-9.,]/g, '')
        .replace(/\./g, ',')
        .replace(/,(?=.*,)/g, '')
        .replace(/^,/, '');
    },
    updateQuantity(e) {
      const symbol = this.symbols[this.order.deal.general.symbol];
      let pattern = /^\d{0,9}$/;
      if (symbol.asset_category === 'OPT') pattern = /^\d{0,9}$/;
      else if (symbol.asset_category === 'CRYPTO') pattern = /^\d{0,9}(,?\d{0,5})?$/;
      // let pattern = [9, 0];
      // if (symbol.asset_category === 'OPT') pattern = [9, 0];
      // else if (symbol.asset_category === 'CRYPTO') pattern = [9, 5];

      // const separation = e.target.value.split(',');
      // if (separation.length === 0) this.changeOrder({ quantity: 0.0 });

      // let value = separation[0].slice(-1 * pattern[0]);
      // if (separation[1] && pattern[1] > 0) value += '.' + separation[1].slice(0, pattern[1]);
      // e.target.value = value;
      // this.changeOrder({ quantity: parseFloat(value) });
      pattern.test(e.target.value)
        ? this.changeOrder({ quantity: parseFloat(e.target.value.replace(',', '.') || '0') })
        : (e.target.value = this.order.deal.general.quantity.toString().replace('.', ','));
    },
    updatePrice(e) {
      const symbol = this.symbols[this.order.deal.general.symbol];
      const field = e.target.closest('.item').dataset.field;
      let pattern = /^[1-9]\d{0,9}(,?\d{0,2})?$/;
      if (symbol.asset_category === 'OPT') pattern = /^[1-9]\d{0,9}(,?\d{0,2})?$/;
      else if (symbol.asset_category === 'CRYPTO') pattern = /^[1-9]\d{0,9}(,?\d{0,8})?$/;
      // let pattern = [9, 2];
      // if (symbol.asset_category === 'OPT') pattern = [9, 2];
      // else if (symbol.asset_category === 'CRYPTO') pattern = [9, 8];

      // const separation = e.target.value.split(',');
      // if (separation.length === 0) this.changeOrder({ [field]: 0.0 });

      // let value = separation[0].slice(-1 * pattern[0]);
      // if (separation[1] && pattern[1] > 0) value += '.' + separation[1].slice(0, pattern[1]);
      // e.target.value = value;
      // this.changeOrder({ [field]: parseFloat(value) });
      pattern.test(e.target.value)
        ? this.changeOrder({ [field]: parseFloat(e.target.value.replace(',', '.') || '0') })
        : (e.target.value = this.order.deal.orders[1][field] === 0.0 ? '' : this.order.deal.orders[1][field].toString().replace('.', ','));
    },
    showModalMassOrderForm() {
      this.$refs.modalForm.show = true;
    },
    getOrderPrice() {
      const symbol = this.order.deal.general.symbol;
      if (symbol && this.levelI[symbol] && this.levelI[symbol].bid && this.levelI[symbol].ask) {
        return this.order.deal.orders[1].action === 'buy' ? this.levelI[symbol].ask : this.levelI[symbol].bid;
      }
      const data = this.symbols[symbol];
      if (data && data.trade) return data.trade.price;
      if (data && data.lp) return data.lp;
      return 0.0;
    },
    printLevelI(name, type) {
      if (this[name] === null) return 'Нет данных';
      if (type === 'currency') return utils.getLocateCurrency(this[name]);
      return this[name];
    },
    updateLevelI(newState) {
      const symbol = this.order.deal.general.symbol;
      if (newState[symbol]) {
        if (this.bid !== newState[symbol].bid) this.bid = newState[symbol].bid;
        if (this.bidSize !== newState[symbol].bidSize) this.bidSize = newState[symbol].bidSize;
        if (this.ask !== newState[symbol].ask) this.ask = newState[symbol].ask;
        if (this.askSize !== newState[symbol].askSize) this.askSize = newState[symbol].askSize;
        // if (!this.order.deal.orders[1].limitPrice) this.changeOrder({ limitPrice: this.getOrderPrice() });
      }
    },
    updateOrder(newState) {
      if (newState.deal.orders[1].type === 'market') {
        this.changeOrder({ triggerPrice: 0.0, limitPrice: 0.0 });
      } else {
        const price = this.getOrderPrice();
        if (newState.deal.orders[1].type === 'limit') {
          const change = {};
          if (!newState.deal.orders[1].limitPrice) change.limitPrice = price;
          change.triggerPrice = 0.0;
          this.changeOrder(change);
        } else if (newState.deal.orders[1].type === 'stop') {
          if (!newState.deal.orders[1].triggerPrice) {
            this.changeOrder({ triggerPrice: price, limitPrice: newState.deal.orders[1].limitPrice || 0.0 });
          }
        }
        if (price === 0.0) setTimeout(() => this.updateOrder(newState), 2000);
      }
    },
    keyProcessing(e) {
      if (['ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
        e.preventDefault();
        if (e.key === 'Tab') {
          const allActiveItem = Array.from(e.target.closest('form').querySelectorAll('.item')).filter(
            (item) => !item.querySelector('input:disabled'),
          );
          const index = allActiveItem.findIndex((item) => item.dataset.field === e.target.closest('.item').dataset.field);
          if (index !== -1) {
            const nextIndex = (index + 1) % allActiveItem.length;
            allActiveItem[nextIndex].querySelector('input').focus();
          }
        } else {
          let step = e.target.closest('.item').dataset.field === 'quantity' ? 1.0 : 0.01;
          if (e.shiftKey) step = step * 10;
          if (e.key === 'ArrowUp') e.target.value = utils.toLocaleString(utils.toFloat(e.target.value) + step);
          if (e.key === 'ArrowDown') e.target.value = utils.toLocaleString(utils.toFloat(e.target.value) - step);
          e.target.dispatchEvent(new Event('input'));
        }
      }
    },
    scrollProcessing(e) {
      const active = document.activeElement;
      if (active.tagName === 'INPUT') {
        e.preventDefault();
        let step = active.closest('.item').dataset.field === 'quantity' ? 1.0 : 0.01;
        active.value = utils.toLocaleString(utils.toFloat(active.value) + step * Math.round(e.deltaY * 2));
        active.dispatchEvent(new Event('input'));
      }
    },
    handleTouchMove(e) {
      e.preventDefault();
      if (this.touch.startX === 0.0) this.touch.startX = e.touches[0].screenX;
      const changeX = this.touch.startX - e.touches[0].screenX;
      if (changeX > 25) this.changeOrder({ action: 'buy' });
      if (changeX < -25) this.changeOrder({ action: 'sell' });

      if (this.touch.startY === 0.0) this.touch.startY = e.touches[0].screenY;
      const active = document.activeElement;
      if (active.tagName === 'INPUT') {
        if (this.touch.value === 0.0) this.touch.value = active.value;
        let step = active.closest('.item').dataset.field === 'quantity' ? 1.0 : 0.01;
        const changeY = this.touch.startY - e.touches[0].screenY;
        if (changeY > 5.0) active.value = utils.toLocaleString(utils.toFloat(this.touch.value) + step * Math.round(Math.abs(changeY) / 5));
        if (changeY < -5.0) active.value = utils.toLocaleString(utils.toFloat(this.touch.value) - step * Math.round(Math.abs(changeY) / 5));
        active.dispatchEvent(new Event('input'));
      }
    },
    handleTouchEnd() {
      this.touch.startX = 0.0;
      this.touch.startY = 0.0;
      this.touch.value = 0.0;
    },
  },
  watch: {
    levelI: {
      handler(newState) {
        this.updateLevelI(newState);
      },
      deep: true,
    },
    order: {
      handler(newState) {
        // if (this.timeoutId) clearTimeout(this.timeoutId);
        // this.timeoutId = setTimeout(() => this.updateOrder(newState), 1000);
        this.updateOrder(newState);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    if (this.order.deal.general.symbol) this.pullQuoteSymbol({ symbol: this.order.deal.general.symbol });
    this.updateOrder(this.order);
    const form = document.querySelector('.order-form');
    form.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    form.addEventListener('touchend', this.handleTouchEnd, { passive: false });
  },
  unmounted() {
    const form = document.querySelector('.order-form');
    if (form) {
      form.removeEventListener('touchmove', this.handleTouchMove);
      form.removeEventListener('touchend', this.handleTouchEnd);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.icon-float {
  width: 15px;
  height: 15px;
  padding: 0 !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;

  &.non-floating {
    top: auto;
    bottom: 0;
    transform: rotateZ(-180deg);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotateY(-180deg);
  }
}

.order-form {
  background: var(--table-header-bg-color);
  box-shadow: -1px 1px 3px var(--table-header-shadow-color);
  padding: 8px 12px 10px;
  border-radius: var(--main-border-radius);

  .form {
    position: relative;
  }

  textarea {
    width: 100%;
    background-color: var(--input-alt-default-bg);
    border: 1px solid var(--input-alt-default-border-outside);
    color: var(--input-alt-default-text);
    box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
    border-radius: var(--main-border-radius);
    padding: 15px;
    height: 100px;
    font-size: 11px;
    font-weight: 600;
  }
}

.ticker-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  margin-bottom: 10px;

  div {
    padding: 0 20px;
  }
}

.order-form-bottom {
  padding-top: 15px;
  border-top: 1px solid var(--input-alt-default-border-outside);

  button {
    width: 130px;
  }
}

.bid,
.ask {
  position: relative;
  padding-left: 16px;
  height: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .title {
    margin-right: 8px;
    font-size: 12px;
  }

  .triangle {
    width: 0;
    height: 0;
    margin-right: 2px;

    &.up {
      @extend .triangle;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid var(--profit-green);
    }

    &.down {
      @extend .triangle;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 10px solid var(--loss-red);
    }
  }

  sup {
    color: var(--white);
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }
}

.bid {
  color: var(--profit-green);
}

.ask {
  color: var(--loss-red);
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 12px 0 14px;

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    height: 100%;
  }
}

.cost {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid var(--input-alt-default-border-outside);

  &-value {
    transform: translate(0, 3px);
  }
}

.btn {
  border-radius: var(--main-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--buy-button-default-text-color);
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @include breakpoint-down(md) {
    height: 35px;
  }
}

.primary {
  @include breakpoint-down(md) {
    height: 35px;
  }
}

//.vibrant {
//  text-transform: uppercase;
//  font-weight: 600;
//  border: 1px solid rgb(46, 112, 228, 0.4);
//  box-shadow: 0 4px 5px rgb(0 0 0);
//}

.buy {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(0, 128, 0, 0);
  border: solid 1px rgba(0, 255, 0, 0.6);
  margin-right: 5px;

  &.active {
    background: rgba(0, 255, 0, 0.6);
  }
}

.danger {
  border: 1px solid var(--alert);
}

.sell {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(255, 0, 0, 0);
  border: solid 1px rgba(255, 0, 0, 0.7);
  margin-left: 5px;

  &.active {
    background: rgba(255, 0, 0, 0.7);
  }
}
</style>
